<template>
  <div>
    <location />
    <div class="page mt-0 login">
      <div class="wrap">
        <b-form name="login_frm" @submit="onSubmit">
          <input
            type="text"
            class="form-control form-control-lg"
            :placeholder="
              $t('text.common.field_enter', {
                field: $t('text.common.id'),
              })
            "
            :aria-label="
              $t('text.common.field_enter', {
                field: $t('title.common.id'),
              })
            "
            v-model="form.id"
            name="id"
            autocomplete="off"
          />
          <input
            type="password"
            class="form-control form-control-lg my-4"
            :placeholder="
              $t('text.common.field_enter', {
                field: $t('title.common.password'),
              })
            "
            :aria-label="
              $t('text.common.field_enter', {
                field: $t('title.common.password'),
              })
            "
            v-model="form.pw"
            name="pw"
            autocomplete="off"
          />
          <div class="row g-0 mb-4">
            <div class="col-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="y"
                  name="remember_yn"
                  id="flexCheck"
                  v-model="form.remember_yn"
                  true-value="y"
                  false-value="n"
                  :checked="form.remember_yn === 'y'"
                />
                <label class="form-check-label" for="flexCheck">{{
                  $t("button.common.remember_id")
                }}</label>
              </div>
            </div>
            <div class="col-6 text-end">
              <router-link to="/Find_Id_Pwd">{{
                $t("text.login.forgot_id_password")
              }}</router-link>
            </div>
          </div>
          <input
            class="btn btn-primary btn-lg w-100"
            type="submit"
            :value="$t('button.common.login')"
          />
          <!-- Social Login UI -->
          <hr />
          <GoogleLogin
            class="btn-outline-secondary btn-lg mb-4 position-relative w-100"
            :params="googleParams"
            :onSuccess="googleSuccess"
            :onFailure="onFailure"
            type="button"
          >
            <span class="icon-sprite-social icon-google"></span>
            {{ $t("button.common.google_login") }}
          </GoogleLogin>

          <!-- <button
            type="button"
            class="btn-outline-secondary btn-lg mb-4 position-relative w-100"
            id="facebook"
            @click="facebookLogin()"
          >
            <span class="icon-sprite-social icon-facebook"></span>
            페이스북 로그인
          </button> -->

          <button
            type="button"
            class="btn-outline-secondary btn-lg mb-4 position-relative login-button w-100"
            @click="vueAppleLogin"
          >
            <span class="icon-sprite-social icon-apple"></span
            >{{ $t("button.common.apple_login") }}
          </button>

          <vue-apple-login
            style="display: none"
            :onSuccess="appleSuccess"
            :onFailure="onFailure"
            ref="apple"
          ></vue-apple-login>
          <p class="text-subscript text-center fs-14">
            <span class="material-icons-outlined me-1">info</span>
            <span>{{ $t("text.login.sign_up_yet") }}</span>
            <router-link
              :to="{ name: 'SignUp' }"
              class="d-inline-block ms-1 text-green"
            >
              {{ $t("text.login.sign_up") }}
            </router-link>
          </p>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import ax from "@/api/auth";
import _ from "lodash";
import location from "@/components/Location";
import facebook from "@/social/facebook.js";
import apple from "@/social/apple.js";
import google from "@/social/google.js";
import GoogleLogin from "vue-google-login";

// import { loadScript } from "vue-plugin-load-script";

// dayjs.tz.setDefault("Australia/Sydney");

export default {
  name: "Login",
  data() {
    return {
      form: {
        id: localStorage.getItem("remember_id") || "",
        pw: "",
        remember_yn: localStorage.getItem("remember_yn") || "",
      },
      loginError: false,
      googleParams: { client_id: process.env.VUE_APP_SOCIAL_GOOGLE_CLIENT_ID },
      publishableKey: "pk_test_TYooMQauvdEDq54NiTphI7jx",
      successURL: "https://testmember.thegolf.com",
      cancelURL: "https://testshop.thegolf.com",
      loading: false,
      lineItems: [
        {
          price: "1000",
          quantity: 1,
        },
      ],
    };
  },
  components: {
    location,
    GoogleLogin,
  },
  created() {
    console.log("dayjs.tz;", this.$dayjs.utc(new Date()));
    if (this.$store.state.user !== null) {
      // this.$router.push('/dashboard')
    }
    this.form = _.cloneDeep(this.form);

    // loadScript(
    //   `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`
    // ).then(() => {
    //   // if (!window.APPLE) apple.init();
    // });
    if (!window.FB) facebook.init();
  },

  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    vueAppleLogin() {
      this.$refs.apple.$el.click();
    },
    onSubmit(event) {
      event.preventDefault();
      if (this.form.id === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.id"),
          })
        );
        return false;
      }

      if (this.form.pw === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.password"),
          })
        );
        return false;
      }

      ax.getToken(this.form, (flag, data) => {
        if (flag) {
          if (sessionStorage.getItem("is_initialize") == "1") {
            alert(this.$i18n.t("alert.membership.need_edit_profile"));
            this.$router.push({ name: "MyProfile" });
            return false;
          }
          this.$router.push("/membership/mysummary");
        } else {
          this.resetForm();
          alert(data.message);
          if (data.code === 301) {
            if (
              confirm(
                this.$i18n.t("alert.membership.receive_verification_email")
              )
            ) {
              ax.verifyEmail(this.form, (flag, code, message) => {
                if (flag) {
                  if (code === 200) {
                    alert(message);
                    // this.$router.push('/login')
                    this.$router.push({ name: "Login" });
                    return true;
                  } else {
                    // alert(code)
                    //alert('code가 200이 아님')
                    return false;
                  }
                } else {
                  alert(message);
                  return false;
                }
              });
            }
          }
        }
      });
    },
    resetForm() {
      this.form = _.cloneDeep(this.form);
    },
    facebookLogin() {
      if (sessionStorage.getItem("token")) {
        alert(this.$i18n.t("alert.login.already_logged_in"));
        this.$router.push("/membership/mysummary");
      } else {
        facebook.login();
      }
    },
    async googleSuccess(googleUser) {
      if (sessionStorage.getItem("token")) {
        alert(this.$i18n.t("alert.login.already_logged_in"));
        this.$router.push("/membership/mysummary");
      } else {
        google.login(googleUser);
      }
    },
    async appleSuccess(appleUser) {
      console.log(appleUser);
      if (sessionStorage.getItem("token")) {
        alert(this.$i18n.t("alert.login.already_logged_in"));
        this.$router.push("/membership/mysummary");
      } else {
        apple.login(appleUser);
      }
    },
    onFailure(error) {
      console.log(error);
    },
  },
};
</script>
